<template>
    <div id="app" :class="{noScroll: showModal}">
        <Header/>
        <router-view></router-view>
        <Footer/>

    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Header from './components/Header/Index';
    import Footer from './components/Footer/Index';

    export default {
        name: 'app',
        components: {
            Header, Footer
        },
        computed: {
            ...mapState([
                'showModal'
            ]),
        },
    }
</script>

<style scoped lang="scss">
    .noScroll {
        overflow: hidden;
        margin: 0;
        height: 100vh;
    }
</style>
