import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Home from '../components/Main/Index';
import Unsubscribe from '../components/Unsubscribe/Index';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/unsubscribe',
        name:'unsubscribe',
        component: Unsubscribe
    }
];

const router = new VueRouter({
    routes
});

export default router;