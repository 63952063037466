<template>
    <div class="loader">
        <div class="loader__arrow"></div>
        <div class="loader__text">loading...</div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>

</style>